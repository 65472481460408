import React from 'react';
import { Link } from 'react-router-dom';

export default class BlogSectionThree extends React.Component {
    render(){
        let publicUrl = process.env.PUBLIC_URL+'/'
        return (
            <>
                <section className="blog-three">
                    <div className="container">
                        <div className="section-title text-center">
                            <span className="section-title__tagline">Our News Post</span>
                            <h2 className="section-title__title">LIFLEA Program <br />News.</h2>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="100ms">
                                {/* Blog Three Single */}
                                <div className="blog-three__single">
                                    <div className="blog-three__img">
                                        <img src={publicUrl+"assets/images/blog/blog-1.jpg"} alt="" />
                                    </div>
                                    <div className="blog-three__content">
                                        <ul className="list-unstyled blog-three__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-clock"></i> by Admin </Link></li>
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-calendar"></i> June 27, 2024</Link></li>
                                        </ul>
                                        {/* <h4 className="blog-three__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>LIFLEA's executives and heads of county chapters and women ancillary associations benefit from intensive capacity development training.</Link></h4> */}
                                        <h4 className="blog-three__title"><Link to={process.env.PUBLIC_URL + `https://web.facebook.com/share/p/ZnxHReCHYRfkyRqU/`}>LIFLEA's executives and heads of county chapters and women ancillary associations benefit from intensive capacity development training.</Link></h4>
                                        <div className="blog-three__person">
                                            <div className="blog-three__person-img">
                                                <img src={publicUrl+"assets/images/blog/blog-3-person-img-1.jpg"} alt="" />
                                            </div>
                                            <div className="blog-three__person-name">
                                                <p><span>By </span> Admin</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="200ms">
                                {/* Blog Three Single */}
                                <div className="blog-three__single">
                                    <div className="blog-three__img">
                                        <img src={publicUrl+"assets/images/blog/blog-2.jpg"} alt="" />
                                    </div>
                                    <div className="blog-three__content">
                                        <ul className="list-unstyled blog-three__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-clock"></i> by Admin </Link>
                                            </li>
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-calendar"></i>July 2, 2024</Link>
                                            </li>
                                        </ul>
                                        <h3 className="blog-three__title"><Link to={process.env.PUBLIC_URL + `https://web.facebook.com/share/p/Nycofy16nnMLk1Da/`}>LIFLEA will leaverage on this high-level regional engagement to strengthen network at International Association of Women Police Conference.</Link></h3>
                                        <div className="blog-three__person">
                                            <div className="blog-three__person-img">
                                                <img src={publicUrl+"assets/images/blog/blog-3-person-img-2.jpg"} alt="" />
                                            </div>
                                            <div className="blog-three__person-name">
                                                <p><span>By</span> Admin</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="300ms">
                                {/* Blog Three Single */}
                                <div className="blog-three__single">
                                    <div className="blog-three__img">
                                        <img src={publicUrl+"assets/images/blog/blog-3.jpg"} alt="" />
                                    </div>
                                    <div className="blog-three__content">
                                        <ul className="list-unstyled blog-three__meta">
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-clock"></i> by Admin </Link>
                                            </li>
                                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}><i className="far fa-calendar"></i> July 12, 2024</Link>
                                            </li>
                                        </ul>
                                        {/* <h3 className="blog-three__title"><Link to={process.env.PUBLIC_URL + `/blog-details`}>67 Females Law Enforcement Officers Obtained Certificate in Basic Computer Literacy and Database Design and Management</Link></h3> */}
                                        <h3 className="blog-three__title"><Link to={process.env.PUBLIC_URL + `https://web.facebook.com/share/p/Et4CPCk5EgWGRhSM/`}>67 Females Law Enforcement Officers Obtained Certificate in Basic Computer Literacy and Database Design and Management</Link></h3>
                                        <div className="blog-three__person">
                                            <div className="blog-three__person-img">
                                                <img src={publicUrl+"assets/images/blog/blog-3-person-img-3.jpg"} alt="" />
                                            </div>
                                            <div className="blog-three__person-name">
                                                <p><span>By</span> Admin</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}